@import '../../styles/customMediaQueries.css';

.languageSwitcher {
  margin-top: 21px;
  margin-right: 15px;
  cursor: pointer;

  @media (--viewportMaxLarge) {
    margin-top: 10px;
    margin-left: 0;
  }

  select {
    display: block;
    width: 45px;
    margin: 0;
    padding: 6px 0 6px 0;
    font-size: 14px;
    border: none;
    outline: none;
    box-shadow: none;
    appearance: revert;
    background-image: none;
    cursor: pointer;

    &:hover {
      border: none;
      cursor: pointer;
    }

    &:focus {
      border: none;
      cursor: pointer;
    }

    &:disabled {
      opacity: 0.4;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  option {
    &.language {
      font-size: 10px;
      line-height: 2px;
    }
  }
}
